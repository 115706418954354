import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['navigation']

  toggle() {
    event.currentTarget.classList.toggle('open')
    this.navigationTarget.classList.toggle('open')
  }
}
