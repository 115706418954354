import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ['code']
  static values = { url: String }

  async add() {
    const response = await patch(this.urlValue, { body: { discount_code: this.codeTarget.value } })

    if (response.ok) {
      const body = await response.text
      Turbo.renderStreamMessage(body)
    }
  }
}
