import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  get inputValue() {
    return parseInt(this.inputTarget.value || 0)
  }

  decrease() {
    if (this.inputValue <= 0) { return }

    this.inputTarget.value = this.inputValue - 1
  }

  increase() {
    this.inputTarget.value = this.inputValue + 1
  }
}
